import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LisFormFieldExtComponent, LisHtmlInputEvent } from '@lis-form';
import { FileUploaderService, ToastService } from '@lis-services';
import { LisDcuplDocument, LisFile, LisFileType } from '@lis-types';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lis-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent
  extends LisFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) override control?: FormControl<File[] | null>;
  @Input() accept?: LisFileType | LisFileType[];

  @ViewChild('dragArea') dragAreaRef?: ElementRef<HTMLDivElement>;

  public files?: LisFile[];

  public isDragDropActive = false;

  private subscriptions = new Subscription();
  private existingDocuments?: LisDcuplDocument[];

  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef,
    private fileUploaderService: FileUploaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.listenForExistingDocuments();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('dragover', ['$event']) public dragOver(
    event: DragEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.isDragDropActive) {
      this.dragAreaRef?.nativeElement.classList.add('bg-interactive-hover');
    }
    this.isDragDropActive = true;
  }
  @HostListener('dragleave', ['$event']) public dragleave(
    event: DragEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragAreaRef?.nativeElement.classList.remove('bg-interactive-hover');
    this.isDragDropActive = false;
  }

  @HostListener('drop', ['$event']) public drop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragAreaRef?.nativeElement.classList.remove('bg-interactive-hover');
    this.isDragDropActive = false;
    const files = event.dataTransfer?.files;

    this.addFilesToList(files as FileList);
  }

  private listenForExistingDocuments(): void {
    this.subscriptions.add(
      this.fileUploaderService.existingDocuments$.subscribe(
        (existingDocuments) => {
          this.existingDocuments = existingDocuments;
          this.refreshFiles(this.control?.value ?? []);
          this.cdRef.detectChanges();
        }
      )
    );
  }

  public onInputFileChanged(event_: Event): void {
    const event = event_ as LisHtmlInputEvent;
    if (!event || !event.target) {
      throw new Error('Files could not be added');
    }

    this.addFilesToList(event.target.files as FileList);

    event.target.value = '';

    this.cdRef.detectChanges();
  }

  private addFilesToList(filelist: FileList): void {
    const files = Object.values(filelist ?? {}) ?? [];

    if (!files) {
      return;
    }

    const filteredFiles = files.filter((file) => {
      if (
        this.accept &&
        ((isArray(this.accept) &&
          !this.accept.includes(file.type as LisFileType)) ||
          (!isArray(this.accept) && this.accept !== file.type))
      ) {
        this.toastService.show({
          type: 'error',
          title: `${this.translateService.instant('docs.toast-error.wrong-filetype.title')}: ${file.name}!`,
          subtitle: 'docs.toast-error.wrong-filetype.subtitle',
        });
        return false;
      }

      // check if file is smaller than 200MB
      if (file.size > 200 * 1024 * 1024) {
        this.toastService.show({
          type: 'error',
          title: `${this.translateService.instant('docs.toast-error.file-too-large.title')}: ${file.name}!`,
          subtitle: 'docs.toast-error.file-too-large.subtitle',
        });
        return false;
      }

      if (this.files?.some((f) => f.file.name === file.name)) {
        this.toastService.show({
          type: 'error',
          title: `${this.translateService.instant('docs.toast-error.rename-file.title')}: ${file.name}!`,
          subtitle: 'docs.toast-error.rename-file.subtitle',
        });
        return false;
      }
      return true;
    });

    const allFiles = [
      ...(this.files?.map((file) => file.file) ?? []),
      ...filteredFiles,
    ];
    this.control?.setValue(allFiles);
    this.refreshFiles(allFiles);
  }

  private isFileAlreadyExisting(fileName: string): boolean {
    return (
      this.existingDocuments?.map((file) => file.fileName).includes(fileName) ??
      false
    );
  }

  private refreshFiles(files: File[]): void {
    this.files = files.map((file) => ({
      file,
      isAlreadyExisting: this.isFileAlreadyExisting(file.name),
    }));
  }

  public onRemoveFileClick(file: File): void {
    this.files = (this.files ?? []).filter((f) => f.file !== file);
    this.control?.setValue(this.files.map((f) => f.file));
  }

  public trackByIndex(index: number): number {
    return index;
  }
}
